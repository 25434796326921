import React from 'react';
import Header from '../components/Header'
import '../styles/privacy-policy.css'
import Footer from '../components/Footer';
import Nav from '../components/Nav'

const PrivacyPolicy = () => {

    return(
        <div id="privacy_policy">
            <Nav></Nav>
            <Header />
                <div id="privacy_policy_copy">
                <h1>PRIVACY POLICY</h1>

<p>This Privacy Policy describes the privacy practices of Insurance321. It explains when and how we collect visitor or customer information, including Personally Identifiable Information, how such information is used, and the circumstances under which information may be disclosed to others.</p>
<p>When you visit Insurance321.com, we may obtain the following types of information from you or concerning your computer or device, which may include information that can itself be used to identify and contact you: Name, Email Address, Phone Number, Company Name (if applicable)</p>
<p>Insurance321.com may also receive certain information that may be provided by your browser or mobile device, including: Browser Information, Operating System Information, Mobile Device Information (e.g., device identifier, mobile operating system, etc.), IP Address, Pages Accessed, Time of Visit, Time of Last Visit, Referring Site, Application, or Service, including the relevant Search Queries that led you to Insurance321.com website</p>
<p>We may use your information to: Enhance or improve the functionality of Insurance321.com products or services; Send email messages and updates about Insurance321.com products or services, including requests for your agreement on policies such as this Privacy Policy or our Terms of Service; Send commercial email messages about our products or services, and respond to inquiries; Perform any other function that we believe in good faith is necessary to protect the security or proper functioning of Insurance321.com products or services.</p>
<h3>Third Party Access to Your Information</h3>
<p>We use third party individuals and organizations, including contractors, web hosting providers, and others, to assist us with the collection, interpretation and use of visitor or user data.</p>
<p>In the course of provisioning or maintaining our website, products or services, we may delegate the authority to collect, access, use, and disseminate your information. For example, our web hosting provider stores the information that you provide us, and we may hire outside contractors to perform maintenance or assist us in securing our website.</p>
<p>With continued use of Insurance321.com products or services, you grant third parties we may use in the course of our business the same rights that you afford us under this Privacy Policy, and for every authorization you grant us under this Privacy Policy, you also authorize any third party that we may hire, contract, or otherwise retain to operate, maintain, repair, or otherwise improve or preserve our website or its underlying systems. You agree not to hold us liable for the actions of any of these third parties, even if we would normally be held vicariously liable for their actions, and you understand that you must take legal action against them directly should they commit any tort or other actionable wrong.</p>
<h3>Release of Your Information for Legal Purposes</h3>
<p>At times it may become necessary or desirable for Insurance321.com to release your information for legal purposes, such as in response to a request from a government agency or a private litigant. You agree that we may disclose your information to a third party where we believe, in good faith, that it is desirable to do so for the purposes of a civil action, criminal investigation, or other legal matter. In the event that we receive a subpoena affecting your privacy, we may elect to notify you to give you an opportunity to file a motion to quash the subpoena, or we may attempt to quash it ourselves, but we are not obligated to do either. We may also proactively report you, and release your information to, third parties where we believe that it is prudent to do so for legal reasons, such as our belief that you may have engaged in fraudulent activities. You release us from any damages that may arise from or relate to the release of your information to a request from law enforcement agencies or private litigants.</p>
<h3>Commercial and Non-Commercial Communications</h3>
<p>By providing your contact information to Insurance321.com via our website, products, or services, you waive all rights to file complaints concerning unsolicited email messages from Insurance321.com, as by providing such information, you agree to receive communications from us, or anyone else covered under this Privacy Policy. You may discontinue receiving certain commercial communications by notifying Insurance321.com you no longer wish to receive solicitations or information other than for account management purposes. Upon receipt of your request, we will endeavor to remove you from our contact database.</p>
<h3>Security Measures</h3>
<p>Insurance321.com has implemented reasonable security mechanisms to protect customer and user data that may be maintained on Insurance321.com servers from loss, misuse and unauthorized access, disclosure, alteration and destruction. Please keep in mind that no security system is totally impenetrable. Despite our best efforts, it may be possible for third parties to intercept or access customer information. Insurance321.com cannot guarantee the security of your information and you agree to not hold Insurance321.com responsible for unauthorized access.</p>
<h3>Our Policy Toward Children</h3>
<p>Our websites, products, and services are not directed to children under 13. We do not knowingly collect personally identifiable information from children under 13. If you are under 13, do not attempt to register or send any information about yourself to us, including your name, address, telephone number, or email address. If a parent or guardian becomes aware that a child has provided us with personally identifiable information, please contact us at hello@Insurance321.com. If we become aware that a child under 13 has provided us with personally identifiable information, we will delete such information from our servers.</p>
<h3>Your California Privacy Rights</h3>
<p>Insurance321.com permits residents of California to use its services. Therefore, it is our intent to comply with the California Business and Professions Code §§ 22575-22579. If you are a California resident, you may request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. Various provisions throughout this Privacy Policy address requirements of these Californian privacy statutes. For CalOPPA compliance, you should presume that Insurance321.com collects electronic information from all visitors. You may contact us at hello@Insurance321.com for additional details.</p>
<h3>International Transfer</h3>
<p>Your information may be transferred to—and maintained on—computers located outside of your state, province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. If you are located outside the United States and choose to provide information to us, Insurance321.com will transfer your Personal Information to the United States and process it there. Your awareness of this Privacy Policy by your submission of such information, and continued use of Insurance321.com websites, products, and services represents your agreement to that transfer.</p>
<h3>Amendments</h3>
<p>Insurance321.com may update this Privacy Policy from time to time without prior notice. When we make material changes to this policy, we may choose to notify you by email message and ask you to accept the changes.  But in any event, we will publish notice of any such material changes on our website, and the continued use of any Insurance321.com website, product or service will constitute your acceptance of those changes.</p>
<p>Last Modified: 10/26/2020</p>
<h3>CALIFORNIA CONSUMER PRIVACY ACT POLICY</h3>
<p>This CALIFORNIA PRIVACY POLICY supplements the terms contained in the Privacy Policy of Insurance321.com, Inc. and its subsidiaries (collectively, “we,” “us,” or “our”) and applies only to visitors, users, and all others who reside in the State of California (“consumers” or “you”). Where the terms of the Insurance321.com Privacy Policy conflict with this policy, the California Privacy Policy shall control for those individuals. This policy is maintained to comply with the California Consumer Privacy Act of 2018 (“CCPA”) and other laws as applicable.  Any terms of art in this policy have the meaning defined in the CCPA.</p>
<h3>Information We Collect</h3>
<p>Our business collects and uses information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer (“personal information”). The following categories of information have been collected within the last twelve (12) months:</p>
<p>Category A:</p>
<p>Identifiers, which may include:  a real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver's license number, passport number, or other similar identifiers.</p>
<p>Category B:</p>
<p>California Customer Records personal information categories, such as: a name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.</p>
<p>‍Personal information does not include:</p>
<p>Publicly available information from government records.</p>
<p>De-identified or aggregated consumer information.</p>
<p>Information excluded under the  CCPA, such as anything covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.</p>
<p>We obtain the categories of personal information listed in the chart from the following categories of sources:</p>
<p>Directly from you or our business partners. For example, when you call us or our business partners for  help to obtain a commercial insurance quote.</p>
<p>Indirectly from agents of our clients. For example, the information from our clients who have received it and, having been unable to fulfill your request for a commercial insurance quote, have passed it to us as part of us providing services to them. </p>
<p>Directly and indirectly from activity on our websites when users submit requests for commercial insurance quotes and website usage details are automatically collected.</p>
<p>From third-parties that interact with us or provide support for the services we perform.</p>
<h3>Use of Personal Information</h3>
<p>We may use or disclose the personal information we collect for one or more of the following business purposes:</p>
<p>To fulfill or meet the reason for which the information is provided. For example, if you provide us with personal information in order for us to fulfill a request  for a commercial insurance quote, we will use that information to prepare a fully qualified quote request and, when possible, connect you to an appropriate business partner.</p>
<p>To provide you with information, products or services that you request from us.</p>
<p>To improve our website and present its contents to you.</p>
<p>For testing, research, analysis and product development.</p>
<p>As necessary to protect the rights, property or safety of us, our business partners or others.</p>
<p>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</p>
<p>As described to you when collecting your personal information or as otherwise set forth in the CCPA.</p>
<p>To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us is among the assets transferred.</p>
<p>Insurance321.com will not collect additional categories of personal information or use the personal information we collected for substantially different or incompatible purposes without providing you notice through this policy.</p>
<h3>Sharing Personal Information</h3>
<p>We may disclose your personal information to a third party for a business purpose.  When we disclose personal information for a business purpose, we enter into a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for anything other than performance of the contract.</p>
<p>In the preceding twelve (12) months, we have disclosed the following categories of personal information for a business purpose:</p>
<p>Category A: Identifiers.</p>
<p>Category B: California Customer Records personal information categories.</p>
<p>We disclose your personal information for a business purpose to the following categories of third parties:</p>
<h3>Our affiliates.</h3>
<p>Service providers.</p>
<p>Third parties to whom you or your agents authorize us to disclose your personal information in connection with products or services we provide to you.</p>
<p>In the preceding twelve (12) months, we have sold personal information only for the exclusive purpose of connecting you with a business partner capable of fulfilling your request for a commercial insurance quote.</p>
<h3>Your Rights and Choices</h3>
<p>The CCPA provides California residents with specific rights. This section describes those rights and explains how to exercise them.</p>
<p>Access to Specific Information and Data Portability Rights</p>
<p>You have the right to request that we disclose certain information to you about the collection and use of your personal information over the past 12 months by completing this form or sending us a request at hello@insurance321.com. Once we receive and confirm your verifiable consumer request, we will disclose to you:</p>
<p>The categories of personal information we collected about you.</p>
<p>The categories of sources for the personal information we collected about you.</p>
<p>Our business or commercial purpose for collecting or selling that personal information.</p>
<p>The categories of third parties with whom we share that personal information.</p>
<p>The specific pieces of personal information we collected about you (also called a data portability request).</p>
<p>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</p>
<p>sales, identifying the personal information categories that each category of recipient purchased; and</p>
<p>disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</p>
<h3>Deletion Request Rights</h3>
<p>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions, by completing this form or sending us a request at hello@insurance321.com. Once we receive and confirm your verifiable consumer request, we will delete your personal information from our records, unless an exception applies.</p>
<p>We may deny your deletion request if retaining the information is necessary for us or our service providers to:</p>
<p>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform actions you authorized.</p>
<p>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</p>
<p>Debug products to identify and repair errors that impair existing intended functionality.</p>
<p>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</p>
<p>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 seq.).</p>
<p>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent.</p>
<p>Enable exclusively internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</p>
<p>Comply with a legal obligation.</p>
<p>Make other lawful uses of your personal information that are compatible in the context of which you provided it.</p>
<h3>Sale of Personal Information Rights </h3>
<p>You have the right to request that we not sell your personal information that we collected from you by completing this form or sending us a request at hello@insurance321.com. Once we receive and confirm your verifiable consumer request, we will not sell your personal information on our platform.</p>
<p>Exercising Access, Data Portability, and Deletion Rights</p>
<p>To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by:</p>
<h3>Completing this form.</h3>
<p>Visiting www.Insurance321.com and clicking on the links in this policy.</p>
<p>Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.</p>
<p>You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:</p>
<p>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.</p>
<p>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</p>
<p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.  Making a verifiable consumer request does not require you to create an account with us.  We will only use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.</p>
<h3>Response Timing and Format</h3>
<p>Our goal is to respond to a verifiable consumer request within 45 days of its receipt.  If we require more time (up to 90 days), we will inform you of the reason and extension period in writing.  We will deliver our written response by mail or electronically, at your option, provided we have valid deliverable addresses for either method.  Any disclosures we provide will only address the 12-month period preceding the verifiable consumer request's receipt.  In our response, we may also explain the reasons why a request cannot be executed, if applicable.  For data portability requests, we will use an ASCII text file or other suitable means to provide your personal information that is readily useable and should allow you to move the information from one entity to another.</p>
<p>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or entirely unfounded.  If we determine that the request warrants a fee, we will tell you why and provide you with the costs before the request is executed.</p>
<h3>Non-Discrimination</h3>
<p>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
<h3>Deny you goods or services.</h3>
<p>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</p>
<p>Provide you a different level or quality of goods or services.</p>
<p>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</p>
<h3>Changes to this Privacy Policy</h3>
<p>We reserve the right to amend this California Privacy Policy at our discretion and at any time.</p>
<h3>Contact Information</h3>
<p>If you have any questions or comments about this policy, our Privacy Policy, the ways in which we collect and use your personal information, your choices and rights regarding such use, or wish to exercise your rights under the CCPA, please contact us and complete this form.</p>
<p>Last Modified: 10/26/2020</p>
                </div>
            <Footer />
        </div>
    )
}

 
export default PrivacyPolicy;